"use client"
import { Value } from "@stringtale/react"

import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle"
import {
  KidGroupIsArchivedError,
  KidOrganizationNotActiveError,
} from "app/auth/errors"
import useLinks from "app/core/hooks/useLinks"
import Image from "next/image"
import { useRouter } from "next/router"
import { FormEvent, useCallback, useId, useState } from "react"
import OtpInput from "react-otp-input"
import {
  DARK_BLUE_COLOR,
  LIGHT_GREEN_COLOR,
  ORANGE_COLOR,
  RED_COLOR,
} from "theme/colors"
import { PX12, PX14, PX16 } from "theme/sizes"
import Button from "ui/Button"
import Checkbox from "ui/Checkbox"
import Container from "ui/Container"
import Group from "ui/Group"
import Stack from "ui/Stack"
import Text from "ui/Text"
import Logo from "../assets/123ZINGkids_logob.svg"
import loginKids from "../mutations/loginKids"
import { Error } from "./Error"

const Root = styled.div`
  background-color: ${LIGHT_GREEN_COLOR};
  height: 100vh;
  display: grid;
  place-items: center;
`

const Modal = styled(Container)`
  box-shadow: 0px 10px 20px #00000033;
  padding: 0 40px;
  padding-top: 48px;
  padding-bottom: 48px;
  background: white;
  box-sizing: border-box;
  border-radius: 16px;
`

const Label = styled.label<{ isError: boolean }>`
  font-size: ${PX14};
  font-weight: 600;
  color: ${({ isError }) => (isError ? RED_COLOR : DARK_BLUE_COLOR)};
`

const SubmitButton = styled(Button)`
  border-radius: 27px;
  background-color: ${ORANGE_COLOR};
  width: 100%;
  height: 54px;
  color: white;
  font-size: ${PX16};
  font-weight: 600;
`

const Input = styled.input<{ isError: boolean }>`
  background: #fcfcfc;
  box-shadow: inset 0px 3px 6px #00000029;
  border: ${({ isError }) =>
    isError ? `2px solid ${RED_COLOR}` : "1px solid #b2b1c9"};
  font-size: ${PX16};
  height: 50px;
  padding: 0 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  flex: 1 0 auto;
  color: inherit;
`

const TextLink = styled.a`
  font-size: ${PX12};
  color: ${ORANGE_COLOR};
  text-decoration: underline;
  cursor: pointer;
`

export default function KidsLoginPage() {
  const [loginMutation] = useMutation(loginKids)
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [remember, setChecked] = useState(false)
  const [isError, setError] = useState(false)
  const [isSchoolError, setSchoolError] = useState(false)
  const router = useRouter()
  const getLink = useLinks()
  const checkboxId = useId()

  const login = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      try {
        await loginMutation({
          code,
          name,
          remember,
        })

        await invalidateQuery()

        const next = router.query.next
          ? decodeURIComponent(router.query.next as string)
          : getLink(Routes.KidsPage())

        // Force refresh to clear the error boundary of the AuthenticationError
        window.location.href =
          typeof next === "string" ? next : next.pathname || next.href
      } catch (e) {
        if (
          e instanceof KidOrganizationNotActiveError ||
          e instanceof KidGroupIsArchivedError
        ) {
          setSchoolError(true)
          setError(false)
        } else {
          setSchoolError(false)
          setError(true)
        }
      }
    },
    [code, getLink, loginMutation, name, remember, router]
  )

  return (
    <Root>
      <Modal width="380px">
        <form onSubmit={login}>
          <Stack gap="20px" align="center">
            <Image
              src={Logo}
              alt=""
              width={193}
              height={113}
              style={{ marginTop: -90 }}
            />
            <Stack gap="20px" align="flex-start">
              <Text size={PX16}>
                <Value name="apps.web.src.kids.components.kidsloginpage.welkom_op_de_123zing_kidspagina">
                  Welkom op de 123ZING Kidspagina.
                </Value>
              </Text>

              {isSchoolError ? (
                <Error>
                  <FaExclamationTriangle size={20} />{" "}
                  <Value name="apps.web.src.kids.components.kidsloginpage.inloggen_werkt_niet_vraag_bij_je_leerkracht_of_jullie_123zing_nog_gebruiken">
                    Inloggen werkt niet. Vraag bij je leerkracht of jullie
                    123ZING nog gebruiken.
                  </Value>
                </Error>
              ) : null}
              <Stack gap="16px" align="flex-start">
                <Stack gap="6px">
                  <Label htmlFor="loginname" isError={isError}>
                    <Value name="apps.web.src.kids.components.kidsloginpage.loginnaam">
                      Loginnaam
                    </Value>
                  </Label>
                  <Input
                    id="loginname"
                    onChange={(e) => setName(e.currentTarget.value)}
                    autoCapitalize="false"
                    isError={isError}
                  />
                </Stack>
                <Stack gap="6px">
                  <Label isError={isError}>
                    <Value name="apps.web.src.kids.components.kidsloginpage.leerlingcode">
                      Leerlingcode
                    </Value>
                  </Label>
                  <OtpInput
                    value={code}
                    onChange={setCode}
                    numInputs={4}
                    containerStyle={{
                      display: "flex",
                      gap: "4px",
                      width: "100%",
                    }}
                    // renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <Input isError={isError} {...props} />
                    )}
                  />
                </Stack>
                {isError ? (
                  <Error>
                    <FaExclamationTriangle size={20} />{" "}
                    <Value name="apps.web.src.kids.components.kidsloginpage.je_loginnaam_of_code_klopt_niet_probeer_het_nog_eens">
                      Je loginnaam of code klopt niet. Probeer het nog eens.
                    </Value>
                  </Error>
                ) : null}
                <Group gap="10px" align="center" style={{ width: "100%" }}>
                  <Checkbox
                    id={checkboxId}
                    onCheckedChange={(e) => setChecked(!!e)}
                  />
                  <label htmlFor={checkboxId}>
                    <Text size={PX14}>
                      <Value name="apps.web.src.kids.components.kidsloginpage.mijn_inloggevens_onthouden">
                        Mijn inloggevens onthouden
                      </Value>
                    </Text>
                  </label>
                </Group>
              </Stack>
            </Stack>
            <SubmitButton type="submit">
              <Value name="apps.web.src.kids.components.kidsloginpage.inloggen">
                Inloggen
              </Value>
            </SubmitButton>
            <TextLink href="https://123zing.nl/faq-kidspagina/" target="_blank">
              <Value name="apps.web.src.kids.components.kidsloginpage.hulp_bij_inloggen">
                Hulp bij inloggen
              </Value>
            </TextLink>
          </Stack>
        </form>
      </Modal>
    </Root>
  )
}
