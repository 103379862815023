"use client"
import styled from "@emotion/styled"
import { RED_COLOR } from "theme/colors"

export const Error = styled.div`
  color: ${RED_COLOR};
  padding: 11px 16px;
  background-color: #E2294A17;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  svg {
    flex-shrink: 0;
  }
`
